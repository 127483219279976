import { BehaviorSubject } from "rxjs";
import { adjustColor, getRgb } from "src/standard/ts/colorUtils";
import { palettes } from "src/theme";

export interface ISitePalette {
  id: string;
  label: string;
  colorMode: "light" | "dark";
  primary: string;
}

const flatPalettes = palettes.flat();

const fromStorage = localStorage.getItem("activePalette");
let fromStoragePalette: ISitePalette | undefined;
if (fromStorage) {
  fromStoragePalette = fromStorage
    ? flatPalettes.find((x) => x.id === fromStorage)
    : undefined;
}

if (!fromStoragePalette) {
  fromStoragePalette = flatPalettes[0]!;
}

export const activePalette$ = new BehaviorSubject<ISitePalette>(
  fromStoragePalette,
);

export function setPrimaryColor(primary: string) {
  const selectors: string[] = [":root", "[data-bs-theme=light]"];

  const primaryHover = adjustColor(primary, -26);
  const primaryRgb = getRgb(primary)?.join(", ") ?? "";
  const primaryHoverRgb = getRgb(primaryHover)?.join(", ") ?? "";

  // Update the css variables for primary from the palette
  for (const selector of selectors) {
    const styleNodes = Array.from(
      document.querySelectorAll(selector) as unknown as HTMLElement[],
    );
    const styles = styleNodes.map((x) => x.style);
    for (const style of styles) {
      const colorPairs: [variable: string, value: string][] = [
        // Set root primary colors
        ["--bs-primary", primary],
        ["--bs-primary-hover", primaryHover],
        ["--bs-primary-rgb", primaryRgb],
        ["--bs-primary-hover-rgb", primaryHoverRgb],
        ["--bs-primary-border-subtle", adjustColor(primary, 26)],

        // Set primary for form controls
        ["--bs-form-radio-circle-fill-color", primary],
        ["--bs-form-radio-circle-fill-color-hover", primaryHover],
        ["--bs-form-radio-border-color", primary],
        ["--bs-form-checkbox-fill-color", primary],
        ["--bs-form-checkbox-fill-color-hover", primaryHover],
        ["--bs-form-toggle-fill-color", primary],
        ["--bs-form-toggle-fill-color-hover", primaryHover],
      ];

      // set css variables
      for (const [variable, value] of colorPairs) {
        style.setProperty(variable, value);
      }

      const lightColorModeStyle = document.createElement("style");
      lightColorModeStyle.innerHTML = `
      [data-bs-theme=light] {
        ${colorPairs.map(([variable, value]) => `${variable}: ${value};`)}
      }
    `;
      document.head.appendChild(lightColorModeStyle);
    }
  }

  // Primary buttons need more help
  const btnPrimaryStyle = document.createElement("style");
  btnPrimaryStyle.innerHTML = `
      .btn-primary {
        --bs-btn-bg: ${primary};
        --bs-btn-border-color: ${primary};
        --bs-btn-focus-shadow-rgb: ${primaryRgb};
        --bs-btn-hover-bg: ${primaryHover};
        --bs-btn-hover-border-color: ${adjustColor(primary, -31)};
        --bs-btn-active-bg: ${adjustColor(primary, -31)};
        --bs-btn-active-border-color: ${adjustColor(primary, -36)};
        --bs-btn-disabled-bg: ${adjustColor(primary, 26)};
        --bs-btn-disabled-border-color: ${adjustColor(primary, 26)};
      }
    `;
  document.head.appendChild(btnPrimaryStyle);

  const btnPrimarySubtleStyle = document.createElement("style");
  btnPrimarySubtleStyle.innerHTML = `
      .btn-outline-primary {
        --bs-btn-color: ${primary};
        --bs-btn-border-color: ${primary};
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: ${primary};
        --bs-btn-hover-border-color: ${primary};
        --bs-btn-focus-shadow-rgb: ${primaryRgb};
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: ${primary};
        --bs-btn-active-border-color: ${primary};
        --bs-btn-disabled-color: ${primary};
        --bs-btn-disabled-border-color: ${primary};
      }
    `;
  document.head.appendChild(btnPrimarySubtleStyle);
}
