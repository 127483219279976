import invariant from "tiny-invariant";

export function getHex(val: string) {
  const ctx = document.createElement("canvas").getContext("2d");
  invariant(ctx);
  ctx.fillStyle = val;
  const tryHex = ctx.fillStyle;
  if (tryHex.startsWith("#")) {
    return tryHex;
  }

  if (tryHex.startsWith("rgba(") && tryHex.endsWith(")")) {
    const [r, g, b, a] = tryHex
      .substring(5, tryHex.length - 1)
      .split(", ")
      .map((x) => Number(x)) as [number, number, number, number];
    const hexA = rgbaToHexA(Number(r), Number(g), Number(b), Number(a));
    return hexA;
  }

  return "";
}

export function rgbaToHexA(r: number, g: number, b: number, a: number) {
  let rStr = r.toString(16);
  let gStr = g.toString(16);
  let bStr = b.toString(16);
  let aStr = Math.round(a * 255).toString(16);

  if (rStr.length == 1) rStr = "0" + r;
  if (gStr.length == 1) gStr = "0" + g;
  if (bStr.length == 1) bStr = "0" + b;
  if (aStr.length == 1) aStr = "0" + a;

  return "#" + rStr + gStr + bStr + aStr;
}

function getLuminance(rgb: [number, number, number]) {
  const [r, g, b] = rgb.map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  }) as [number, number, number];
  return r * 0.2126 + g * 0.7152 + b * 0.0722;
}

export function getContrastRatio(
  rgb1: [number, number, number],
  rgb2: [number, number, number],
) {
  const lum1 = getLuminance(rgb1);
  const lum2 = getLuminance(rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export function adjustColor(color: string, amount: number): string {
  let usePound = false;

  if (color[0] == "#") {
    color = color.slice(1);
    usePound = true;
  }

  let num = parseInt(color, 16);

  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00ff) + amount;
  let b = (num & 0x0000ff) + amount;

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  // Converts a single color component to a 0-padded hex string
  const toHex = (component: number) => {
    const hex = component.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };

  // Concatenates the color components in the correct order
  return (usePound ? "#" : "") + toHex(r) + toHex(g) + toHex(b);
}

export function getRgb(val: string) {
  const { style } = new Option();
  style.color = val;
  const rgb = style.color;

  let justRgb: [number, number, number] | undefined;
  if (rgb.startsWith("rgba(")) {
    const [r, g, b] = rgb
      .substring(5, rgb.length - 1)
      .split(", ")
      .map((x) => Number(x)) as [number, number, number];
    justRgb = [r, g, b];
  } else if (rgb.startsWith("rgb(")) {
    const [r, g, b] = rgb
      .substring(4, rgb.length - 1)
      .split(", ")
      .map((x) => Number(x)) as [number, number, number];
    justRgb = [r, g, b];
  }

  return justRgb;
}
