import { ISitePalette } from "src/standard/ts/theming";

export const palettes: [ISitePalette, ISitePalette][] = [
  [
    {
      id: "light-bdo",
      colorMode: "light",
      label: "BDO Light",
      primary: "#e81a3b",
    },
    {
      id: "dark-bdo",
      colorMode: "dark",
      label: "BDO Dark",
      primary: "#e81a3b",
    },
  ],
  [
    {
      id: "light-blue",
      colorMode: "light",
      label: "Blue Light",
      primary: "#2563eb",
    },
    {
      id: "dark-blue",
      colorMode: "dark",
      label: "Blue Dark",
      primary: "#3b82f6",
    },
  ],
  [
    {
      id: "light-forest",
      colorMode: "light",
      label: "Forest Light",
      primary: "#15803d",
    },
    {
      id: "dark-forest",
      colorMode: "dark",
      label: "Forest Dark",
      primary: "#16a34a",
    },
  ],
  [
    {
      id: "light-purple",
      colorMode: "light",
      label: "Purple Light",
      primary: "#9333ea",
    },
    {
      id: "dark-purple",
      colorMode: "dark",
      label: "Purple Dark",
      primary: "#a855f7",
    },
  ],
];
